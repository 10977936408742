define('ember-image-slider/components/ember-image-slider', ['exports', 'ember', 'ember-image-slider/templates/components/ember-image-slider'], function (exports, _ember, _emberImageSliderTemplatesComponentsEmberImageSlider) {
  var computed = _ember['default'].computed;
  var $ = _ember['default'].$;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberImageSliderTemplatesComponentsEmberImageSlider['default'],
    classNames: ['ember-image-slider'],

    limit: 5,
    scrollLeft: 0,
    containerWidth: null, // computed in didInsertElement() and onResize()
    animationTime: 200,
    shouldDisplayArrows: true,

    convertedContent: computed('content.[]', function () {
      return this.get('content').map(function (image) {
        return _ember['default'].Object.create(image);
      });
    }),

    limitedContent: computed('convertedContent.[].@each', 'limit', function () {
      return _ember['default'].A(this.get('convertedContent').slice(0, this.get('limit')));
    }),

    contentSorting: ['offsetLeft:desc'],
    descendingContent: computed.sort('limitedContent', 'contentSorting'),

    allItemsDisplayed: computed('limit', 'convertedContent.length', function () {
      return this.get('limit') >= this.get('content.length');
    }),

    nextSlide: computed('limitedContent.[]', 'scrollLeft', 'containerWidth', function () {
      var _this = this;

      return this.get('limitedContent').find(function (slide) {
        return slide.get('offsetLeft') > _this.get('scrollLeft') + _this.get('containerWidth') - slide.get('width') * 0.5;
      });
    }),

    previousSlide: computed('descendingContent.[]', 'scrollLeft', function () {
      var _this2 = this;

      return this.get('descendingContent').find(function (slide) {
        return slide.get('offsetLeft') < _this2.get('scrollLeft') && _this2.get('scrollLeft') > 0;
      });
    }),

    setContainerWidth: function setContainerWidth() {
      var $el = $(this.element);
      this.set('containerWidth', $el.width());
    },

    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this.setContainerWidth();

      var $gridWrap = $(this.element).find('.grid-wrap');
      this.set('$gridWrap', $gridWrap);

      //bind events
      $gridWrap.scroll(function (event) {
        _this3.scroll(event);
      });
      $(window).on('resize.' + this.elementId, function () {
        _this3.onResize();
      });
    },

    willDestroyElement: function willDestroyElement() {

      //unbind events
      this.get('$gridWrap').unbind();
      $(window).off('resize.' + this.elementId);
    },

    scroll: function scroll(event) {
      this.set('scrollLeft', this.get('$gridWrap').scrollLeft());
    },

    onResize: function onResize() {
      this.setContainerWidth();
    },

    actions: {
      increaseLimit: function increaseLimit(amount) {
        this.incrementProperty('limit', amount);
      },

      clearPicks: function clearPicks() {
        this.get('convertedContent').forEach(function (image) {
          image.set('picked', false);
        });
      },

      moveLeft: function moveLeft() {
        var gridWrap = this.get('$gridWrap');
        gridWrap.animate({ scrollLeft: this.get('previousSlide.offsetLeft') }, this.get('animationTime'));
      },

      moveRight: function moveRight() {
        var gridWrap = this.get('$gridWrap');
        gridWrap.animate({ scrollLeft: this.get('nextSlide.offsetLeft') - this.get('containerWidth') + this.get('nextSlide.width') + 10 }, this.get('animationTime'));
      }
    }
  });
});