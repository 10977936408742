define("ember-star-rating/templates/components/star-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2tk4Y77C",
    "block": "{\"symbols\":[\"star\",\"index\"],\"statements\":[[4,\"each\",[[22,[\"stars\"]]],null,{\"statements\":[[0,\"  \"],[6,\"svg\"],[11,\"width\",[20,\"width\"]],[11,\"height\",[20,\"height\"]],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",[20,\"viewBox\"]],[8],[0,\"\\n    \"],[6,\"defs\"],[8],[0,\"\\n      \"],[6,\"linearGradient\"],[11,\"id\",[27,[[20,\"elementId\"],\"-star-\",[21,2,[]]]]],[10,\"x1\",\"0%\"],[10,\"y1\",\"0%\"],[10,\"x2\",\"100%\"],[10,\"y2\",\"0%\"],[8],[0,\"\\n        \"],[6,\"stop\"],[10,\"class\",\"star-rating-filled\"],[10,\"offset\",\"0%\"],[11,\"stop-color\",[20,\"fillColor\"]],[8],[9],[0,\"\\n        \"],[6,\"stop\"],[10,\"class\",\"star-rating-base\"],[10,\"offset\",\"0%\"],[11,\"stop-color\",[20,\"baseColor\"]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"path\"],[11,\"fill\",[27,[\"url(#\",[20,\"elementId\"],\"-star-\",[21,2,[]],\")\"]]],[11,\"d\",[20,\"svgPath\"]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-star-rating/templates/components/star-rating.hbs"
    }
  });

  _exports.default = _default;
});