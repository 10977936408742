define('ember-image-slider/components/slider-item', ['exports', 'ember', 'ember-image-slider/templates/components/slider-item'], function (exports, _ember, _emberImageSliderTemplatesComponentsSliderItem) {
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberImageSliderTemplatesComponentsSliderItem['default'],
    classNames: ['slider-item'],
    classNameBindings: ['image.picked'],
    attributeBindings: ['style'],
    style: computed('image.width', 'image.height', function () {
      return htmlSafe('flex:0 0 ' + this.get('image.width') + 'px;');
    }),

    didInsertElement: function didInsertElement() {
      this.set('image.outerWidth', $(this.element).outerWidth());
      this.set('image.offsetLeft', $(this.element).position().left);
    },

    click: function click() {
      var newIsPickedValue = !this.get('image.picked');
      this.get('beforePick')();
      this.set('image.picked', newIsPickedValue);
      var output = this.get('image');
      if (!newIsPickedValue) {
        output = null;
      }
      this.get('afterPick')(output);
    },

    touchStart: function touchStart() {}

  });
});